import React from 'react'
import Banner from '../Components/Banner'
import Main from '../Components/Main'


function Home() {
  return (
    <>
    {/* <PreLoader/> */}
   <Banner/>
   <Main/>
   
    </>
 
  )
}

export default Home
